import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { isTouch } from 'Lib/istouch/istouch';
import { eventSender } from 'Lib/event-sender/event-sender';
import fastdom from 'fastdom';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $parent = $('.cmenu').first();
const $body = $('body');
const $menu = $parent.find('.cmenu__menu').first();
const $menuCont = $parent.find('.cmenu__menu-cont').first();
const sMenuItemMore = '.cmenu__item_more';
const $menuItemMore = $parent.find(sMenuItemMore).first();
const $menuItem = $parent.find(`.cmenu__item:not(${sMenuItemMore})`);
const cMmenuItemHide = 'cmenu__item_hide';
const cBodyMegaActive = 'cmenu__body-mega-active';
const sPanelScroll = '.cmenu__panel-scroll';
const sClose = '.cmenu__panel-close-link';
const sLinkDropDown = '.link-drop-down';
const sLink = '.cmenu__link';
let isOnChange = false;

function setMoreMenu(html) {
    if ($menuItemMore.length) {
        const $itemMoreContent = $menuItemMore.find('.cmenu__item_more-content').first();

        if ($itemMoreContent.length) {
            fastdom.mutate(() => {
                if (isNoEmptyString(html)) {
                    $itemMoreContent.html(html);
                    $menuItemMore.show();
                } else {
                    $itemMoreContent.html('');
                    $menuItemMore.hide();
                }
            });
        }
    }
}

function start() {
    fastdom.measure(() => {
        const $itemMoreContent = $menuItemMore.find('.cmenu__item_more-content').first();
        const wParent = $menu.outerWidth(true);
        const wChildren = $menuCont.outerWidth(true);
        let diff = Math.ceil(wChildren - wParent);
        const wItemMore = $menuItemMore.outerWidth(true);
        let arr$hiddenItem = [];
        const cItemTouchVisible = 'cmenu__item-touch-visible';

        function getItemsForHide($items) {
            const arResult = [];

            for (let i = $items.length - 1; i >= 0; i--) {
                const $item = $items.eq(i);

                if ($item.position().left + $item.outerWidth(true) > wParent - wItemMore) {
                    arResult.unshift($item);
                }
            }

            return arResult;
        }

        function getAttributesLinks(node) {
            let htmlAttributes = '';

            node.attributes.forEach((attr) => {
                if (attr.nodeName && attr.nodeName !== 'class') { // Выбираем именно html-атрибуты, но без аттрибута class
                    htmlAttributes += ` ${attr.nodeName}="${attr.value}"`;
                }
            });

            return htmlAttributes;
        }

        function getClassLinks(node) {
            let htmlClasses = '';

            if (node.classList.contains(cItemTouchVisible)) {
                htmlClasses += ` ${cItemTouchVisible}`;
            }

            return htmlClasses;
        }

        function setItemMenu(classLinks, link, text, th) {
            let html = '';

            if (th) {
                th.find('a').each(function (i) {
                    html += `<a${getAttributesLinks(this)} class="mega-menu__link mega-menu__link_inline a">${$(this).text()}</a>`;

                    if (i === 0) {
                        html += ' / ';
                    }
                });
            } else {
                html = `
                    <a${link} class="mega-menu__link">${text}</a>
                `;
            }

            return `
                <div class="mega-menu__item${classLinks}">
                  ${html}
                </div>
            `;
        }

        function setItemBoxMenu(link, text, childrenLinksHtml, th, icon) {
            let html = '';

            if (th) {
                th.find('a').each(function (i) {
                    html += `<a${getAttributesLinks(this)} class="mega-menu__link mega-menu__link_big mega-menu__link_inline a">${$(this).text()}</a>`;

                    if (i === 0) {
                        html += ' / ';
                    }
                });
            } else {
                html = `
                    <a${link} class="mega-menu__link mega-menu__link_big">${icon || ''}${text}</a>
                `;
            }

            return `
                <div class="mega-menu__cell">
                    <div class="mega-menu__item">
                        ${html}
                    </div>
                    ${childrenLinksHtml}
                </div>
            `;
        }

        function getMoreMenuHtml($items) {
            let newItemHtml = '';
            let $itemsForHide = $();

            $items.forEach(($item) => {
                const $mainLink = $item.find(sLink).first();

                if ($mainLink.length) {
                    const $dropDownDouble = $item.find('.drop-down_double');
                    const $childrenLinks = $dropDownDouble.length ? $item.find('.drop-down__double-col') : $item.find('.drop-down__item');
                    let childrenLinksHtml = '';

                    if ($dropDownDouble.length) {
                        $childrenLinks.each(function () {
                            let linkMenuDouble = '';
                            let textMenuDouble = '';
                            let th = '';

                            $(this).find('.drop-down__item').each(function (i) {
                                if (i === 0) {
                                    linkMenuDouble = getAttributesLinks($(this)[0]);
                                    textMenuDouble = $(this).text();
                                    th = $(this).find('a').length ? $(this) : '';
                                } else {
                                    childrenLinksHtml += setItemMenu(getClassLinks(this), getAttributesLinks(this), $(this).text(), $(this).find('a').length ? $(this) : '');
                                }
                            });

                            newItemHtml += setItemBoxMenu(linkMenuDouble, textMenuDouble, childrenLinksHtml, th);
                            childrenLinksHtml = '';
                        });
                    } else {
                        $childrenLinks.each(function () {
                            childrenLinksHtml += setItemMenu(getClassLinks(this), getAttributesLinks(this), $(this).text());
                        });

                        newItemHtml += setItemBoxMenu(getAttributesLinks($mainLink[0]), $mainLink.text(), childrenLinksHtml, '', $item.find('.cmenu__link-svg-box').html() || '');
                    }

                    $itemsForHide = $itemsForHide.add($item);
                }
            });

            fastdom.mutate(() => {
                $itemsForHide.addClass(cMmenuItemHide);
            });

            return newItemHtml;
        }

        if (diff > 0) {
            diff += wItemMore;
        }

        if (wParent && wChildren && diff > 0 && $itemMoreContent.length) {
            arr$hiddenItem = getItemsForHide($menuItem);
        }

        // Проверка на количество пунктов и подпунктов в "Ещё"
        $itemMoreContent.toggleClass('cmenu__item_more-content_few-points', arr$hiddenItem.length < 11 && !arr$hiddenItem.some((item) => item.find('.drop-down').length));
        //
        setMoreMenu(getMoreMenuHtml(arr$hiddenItem));
    });
}

function changeQTYItem() {
    if ($menu.length && $menuCont.length && $menuItem.length && $menuItemMore.length) {
        if (isOnChange) {
            $menuItem.removeClass(cMmenuItemHide);
            $menuItemMore.hide();
            start();
        } else {
            start();
        }

        isOnChange = true;
    }
}

function afterShowMega($drop) {
    const $dropCont = $drop.find(sPanelScroll).first();
    const $btnClose = $drop.find(sClose).first();
    const $header = $('.cheader').first();

    if ($dropCont.length) {
        const headerHeight = $header.outerHeight() - ($header.hasClass('cheader_fixed') ? 0 : $(window).scrollTop());
        const resultHeightDrop = $(window).outerHeight(true) - headerHeight - ($btnClose.length && isTouch() ? $btnClose.height() : 0);

        if (isTouch() || ($dropCont.height() > resultHeightDrop)) {
            $body.addClass(cBodyMegaActive);
            $dropCont.css({ height: resultHeightDrop });
            disableBodyScroll($dropCont[0]);
        }
    }
}

function afterCloseMega($drop) {
    const $dropCont = $drop.find(sPanelScroll).first();

    $drop.find(sPanelScroll).removeAttr('style');
    $body.removeClass(cBodyMegaActive);

    if ($dropCont.length) {
        enableBodyScroll($dropCont[0]);
    }
}

if ($parent.length) {
    $parent.find(`.cmenu__item_drop-down ${sLink}`).on('click', (e) => {
        if (isTouch()) {
            e.preventDefault();
        }
    });

    $parent.find(`.cmenu__item_drop-down${sLinkDropDown}`).on('eksmoLinkDropDown', function (e, isShow) {
        $(this).toggleClass('cmenu__item_drop-down-show', isShow);
    });

    $parent.find(`.cmenu__block${sLinkDropDown}`).on('eksmoLinkDropDown', function (e, isShow) {
        const $this = $(this);

        $this.find('.cmenu__block-text_drop-down').toggleClass('cmenu__block-text_drop-down-show', isShow);
        $this.find('input[type=email]').first().focus();
    });

    $body.on('eksmoFontRobotoLoad', changeQTYItem);
    $body.on('eksmoResizeWidth eksmoHeaderFixed', changeQTYItem);
    $body.on('eksmoChangeFonts', changeQTYItem);

    $('.cmenu__panel').each(function () {
        const $thisPanel = $(this);
        const cLoad = 'cmenu__load-panel_load';
        const href = $thisPanel.attr('data-href');
        const $drop = $thisPanel.closest(sLinkDropDown);

        $drop.on('eksmoLinkDropDown', (e, isShow) => {
            if (isShow) {
                if (isNoEmptyString(href) && !$thisPanel.hasClass(cLoad)) {
                    $.ajax({
                        url: href,
                        cache: false,
                        success: (data) => {
                            $thisPanel.html(data);
                            $thisPanel.addClass(cLoad);
                            afterShowMega($thisPanel);

                            const elLinkPicImg = document.querySelector('.mega-menu__link-pic-img');
                            const objInfo = {
                                'name': elLinkPicImg?.getAttribute('data-ga4-promo-name'),
                                'id': elLinkPicImg?.getAttribute('data-ga4-promo-id'),
                                'location_id': elLinkPicImg?.getAttribute('data-ga4-promo-location'),
                            };

                            eventSender.promoImpressionsGA4(objInfo);

                            elLinkPicImg?.addEventListener('click', () => {
                                eventSender.promoClickGA4(objInfo);
                            });
                        },
                    });
                } else {
                    afterShowMega($thisPanel);
                }
            } else {
                afterCloseMega($thisPanel);
            }
        });
    });

    $body.on('click', sClose, function () {
        $(this).closest(sLinkDropDown).trigger('eksmoCallLinkDropDownClose');
    });

    $('.cmenu__block-link-journal').on('click', (e) => {
        if (isTouch()) {
            e.preventDefault();
        }
    });
}

$body.on('click', sLink, function () {
    eventSender.clickMainMenu($(this));
});