import { getCookie } from 'Lib/cookies/cookies';

const $body = $('body');
const cookieName = 'COOKIE_ACCEPT';

function setCookie(name) {
    const date = new Date(new Date().getTime() + 60 * 1000 * 60 * 24 * 365 * 10); // на 10 лет

    document.cookie = `EKSMO_${name}_SHOWED=Y; path=/; domain=${window.location.hostname}; expires=${date.toUTCString()}; SameSite=None; Secure`;
}

$body.on('bitrixDynamicComposite.eksmo', () => {
    if (!$('html').hasClass('is-authorized')) {
        if (getCookie(`EKSMO_${cookieName}_SHOWED`) !== 'Y') {
            $(document).ready(() => {
                $body.append('<div class="cookie-accept bg bg_lighterblack"><div class="r10"></div><div class="r visible-md visible-lg"></div><div class="container"><div class="row cookie-accept__row"><div class="col-md-9"><div class="text text_s10 text_s14-d text_white text_lh-1-4">Мы используем файлы cookie и другие средства сохранений предпочтений и анализа действий посетителей сайта. <br>Подробнее в <a href="/publishers/polzovatelskoe-soglashenie/" class="a a_orange a_noline">пользовательском соглашении</a>. Нажмите "принять", чтобы дать свое согласие на это.<div class="r10 hidden-lg hidden-md"></div></div></div><div class="col-md-3"><div class="cookie-accept__btn btn btn_small btn_full-width btn_orange">Принять</div></div></div></div><div class="r10"></div><div class="r"></div></div>');
            });
        }
    }
});

$body.on('click', '.cookie-accept__btn', () => {
    setCookie(cookieName);
    $('.cookie-accept').remove();
});